import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "axios";
import moment from "moment";

import Layout from "../components/Layout/Layout";
import imageSuccess from '../assets/img/image-success.png'
import imageExpired from '../assets/img/image-expired.png'
import "../assets/styles/scss/ck-content.css";
import "../assets/styles/css/styles.css";

const VerifyEmail = (props) => {
  const { token } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [downloadLink, setDownloadLink] = useState('');
  const [isExpire, setIsExpire] = useState(false)
  const [emailSentCount, setEmailSentCount] = useState(0)
  const [seconds, setSeconds] = useState(15)
  const [isActive, setIsActive] = useState(false);
  const [resendEmail, setResendEmail] = useState(true)
  let isActiveStyle = resendEmail ? 'active' : 'non-active'

  useEffect(() => {
    setLoading(true);
    const url = `${process.env.REACT_APP_AXWAY_API_URL}/v1/api/emailverification/verifyemail`
    axios.post(url, {
        token
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'api-key': process.env.REACT_APP_API_KEY
        }
    })
    .then((res) => {
        let count = res?.data?.countResend || 1
        const {data: {description}} = res
        if (description == 'Email verification expired') {
            setIsExpire(true)
        }
        setEmailSentCount(count)
        if (count >=3) {
          setResendEmail(false)
        }
    })
    .catch((err) => {
      if (err.response?.status === 401) {
        setIsExpire(true)
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }, []);

  useEffect(() => {
    let interval;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            clearInterval(interval);
            setIsActive(false);
            setResendEmail(true)
            return 0;
          }
          return prevSeconds - 1;
        });
        if (emailSentCount == 3) {
            setResendEmail(false)
          }
      }, 1000);

    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive]);

  const handleReset = () => {
    if (!isActive && emailSentCount < 3) {
      // setEmailSentCount(emailSentCount + 1)
      const url = `${process.env.REACT_APP_AXWAY_API_URL}/v1/api/emailverification/resendemail`
      axios.post(url, {
          token
      }, {
          headers: {
              'Access-Control-Allow-Origin': '*',
              appRelease: 'release-3',
              'api-key': process.env.REACT_APP_API_KEY
          }
      })
      .then((res) => {
        if (res.data.status == 'failed') {
          if (res.data.description == 'Email has been verified') {
            setIsActive(false);
            setResendEmail(false)
            setSeconds(0);
            setIsExpire(false);
            return
          }
          if (res.data.description == 'resend email sudah lebih dari batas') {
            setIsActive(false);
            setResendEmail(false)
            setSeconds(0);
            setEmailSentCount(3)
            return
          }
        }
        const count = res?.data?.countResend || 1
        const {data: {description}} = res
        if (description == 'Email verification expired') {
          setIsExpire(true)
        }
        setEmailSentCount(count)
        setIsActive(true);
        setResendEmail(false)
        setSeconds(60);
      })
      .catch(e => console.log(e))
    }
  };

  const convertSecondToFormat = second => {
    return moment.utc(second * 1000).format('mm:ss')
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 my-5">
        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        <span className="sr-only">Loading...</span>
      </div>
    );
  } else {
    return (
      <div>
        <Layout 
        downloadLink={downloadLink}
          title={data.title}
          descriptions={data.meta_descriptions}
          keywords={data.meta_keywords}
          url={process.env.REACT_APP_API_URL + '/' + props.location.pathname}
        >
          <div id="berita-detail">
            {isExpire ? 
              (<div className="main-container">
                  <div>
                      <img
                          src={imageExpired}
                          width="300px"
                          height="300px"
                          alt="expired image"
                      />
                  </div>
                  <div className="container-timer">
                      <h3 className="text-kadaluwarsa">Tautan kedaluwarsa😔</h3>
                      <div className="text-container-timer" style={{width: "327px"}}>
                          <p className="text-timer-detail">Yahh, tautan verifikasi email ini sudah tidak berlaku. Silakan kirim ulang email.</p>
                      </div>
                      <a
                          className={`timer ${isActiveStyle}`} 
                          onClick={handleReset}
                    >
                      Kirim ulang email
                    {resendEmail}
                    {seconds > 0 &&
                      isActive &&
                      ' dalam ' +
                      convertSecondToFormat(
                          seconds
                      )}{' '}
                    ({emailSentCount}/3)
                  </a>
                  </div>
              </div>
              ):                 
              (<div className="main-container">
                  <div>
                    <img
                      src={imageSuccess}
                      width="300px"
                      height="300px"
                      alt="success image"
                    />
                  </div>
                  <div className="container-timer">
                    <h3 className="text-kadaluwarsa">Yay, email terverifikasi!</h3>
                    <div className="text-container-timer">
                      <p className="text-timer-detail">Verifikasi email berhasil. Selamat menikmati beragam produk dan fitur yang ada di Moxa, ya!</p>
                    </div>
                    <div>
                      <a className="btn-submit" href="https://moxa.onelink.me/VqhM/rdvk76gy?token=test">Buka Aplikasi Moxa</a>
                    </div>
                  </div>
              </div>
              )
            }
          </div>
        </Layout>
      </div>
    );
  }
};

export default VerifyEmail;
