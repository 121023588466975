import React, { useState, useEffect } from "react";
import axios from "axios";

const HPSection2Fix = (props) => {

  const productsData =
  props.products.length > 0 ? (
    props.products.map((product, i) => (
        <div
          key={i}
          className="section2 col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2"
          style={{
            marginBottom: "30px",
          }}
        >
          <a
            href={`/product/detail/${product.slug}`}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            <img
              className="w-75"
              src={product.icon.url}
              style={{ marginBottom: "15px" }}
              alt={product.name}
            />
            <br />
            <p style={{ fontWeight: 700 }}>{product.name}</p>
          </a>
        </div>
      ))
    ) : (
      <h2>loading..</h2>
    );

  return (
    <div>
      <div id="sub">
        <div className="wrapper">
          <div className="row justify-content-md-center" style={{ marginBottom: "35px" }}>
            <div className="col-lg-10 m-auto">
              <h1>Jelajahi Berbagai Pilihan Produk Moxa</h1>
              <p>
                Rasakan kemudahan mengajukan beragam produk keuangan dalam satu
                sentuhan lewat fitur unggulan Moxa{" "}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="row justify-content-start">
              {productsData}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HPSection2Fix;
